* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Karla', sans-serif;
  background-color: #F5F7FB;
  color: #293264;
  display: flex;
  justify-content: center;
}

.screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  max-width: 750px;
}

button{
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 0.85rem;
  background-color: #4D5B9E;
  color: #F5F7FB;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  padding: 15px 30px;
}
button:disabled {
  background-color: transparent;
  /* color: #4D5B9E; */
  color: #8b90af;
  border: 1px solid #8b90af;
}

/* Title screen */

.title-screen--container {
  height: 100vh;
  justify-content: center;
}
.title-screen--title {
  font-size: 3rem;
  font-weight: bold;
  margin: 0;
}
.title-screen--description {
  font-family: 'Inter', sans-serif;
  max-width: 500px;
  text-align: center;
}
.title-screen--start-button {
  border-radius: 15px;
  margin-top: 20px;
  font-size: 1rem;
}

/* Quiz screen */

.quiz--controls {
  padding-top: 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 25px;
}
.quiz--result {
  font-family: 'Inter', sans-serif;
  font-weight: 700;
}

/* Quiz questions */

.quiz--question {
  border: 0;
  margin: 0 15px;
  padding: 0;
}
.quiz--question-text {
  font-size: 1.3rem;
  margin: 5px 0;
}

.quiz--answer-radios {
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0 0px 0;
}
.quiz--answer-single-radio {
  padding: 5px 10px;
}
.quiz--answer-radios input {
  display: none;
}
.quiz--answer-radios label {
  display: block;
  text-align: center;
  cursor: pointer;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 0.85rem;
  border: 1px solid #4D5B9E;
  border-radius: 10px;
  padding: .35rem 1.5rem; 
}
.quiz--answer-radios input:checked + label {
  background-color: #D6DBF5;
  border-color: transparent;
}

hr {
  color: #dbdef075;
  margin: 15px;

}
